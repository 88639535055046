import { PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import CoachTeamList from 'manage-tritag/components/teams/coach-list'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'

const RegisterCoachVenuePage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [queryPrams, setQueryPrams] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const q = params.get('q')
    if (q) {
      setQueryPrams(q)
    }
  }, [])

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <CoachTeamList q={queryPrams} />
    </>
  )
}

export default withAuthenticationRequired(RegisterCoachVenuePage)
